import React from 'react'
import Helmet from 'react-helmet'
//import { Grid, Row, Col } from 'react-flexbox-grid'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import findImage from '../../utils/findImage'
import Layout from '../../components/Layout'
import WorkTitle from '../../components/WorkTitle'
import Image from '../../components/Image'

import styles from './flare.module.scss'

import flareLogoImg from './i/flare-logo-orange.svg'
import flashlightImg from './i/flashlight.svg'
import WorkTitleBlock from '../../components/WorkTitleBlock';

export const frontmatter = {
  title: 'Flare Logo',
  type: 'Identity',
  client: 'Flare',
  path: '/works/identity/flare-logo/',
  date: '2014-11-04',
  image: 'flare-logo-demo',
  favorite: true,
  related: '/works/web/flare-site/',
}

const related = [
  {
    title: 'Site',
    href: frontmatter.related,
  },
  {
    title: 'Identity',
    href: frontmatter.path,
  },
]

const FlareLogo = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet title={frontmatter.title} />

      <section className={styles.section + ' ' + styles.section1}>
        <WorkTitle title={frontmatter.title} related={related} />

        <p className="aligncenter text-center">
          Logo design for a modern digital agency.
        </p>

        <figure className={styles.mainImage}>
          <Image src={flareLogoImg} alt="Flare logo" />
        </figure>
      </section>

      <FlashlightSection />
      <LighthouseSection />
      <StationerySection />
    </Layout>
  )
}

const FlashlightSection = () => {
  return (
    <section className={styles.section + ' ' + styles.flarshlightSection}>
      <span className={styles.flarshlightContainer}>
        <span className={styles.flashlight}>
          <div className={styles.switch} />
          <Image src={flashlightImg} alt="Flashlight" />
        </span>
        <Image
          className={styles.flarshlightLogo}
          src={flareLogoImg}
          alt="Flare Logo"
          width="2000"
          height="800"
        />
      </span>
    </section>
  )
}

const LighthouseSection = () => {
  return (
    <section className={styles.section + ' ' + styles.lighthouseSection}>
      <div className={styles.lighthouseLogo}>
        <Image src={flareLogoImg} alt="Flare Logo" />
      </div>
    </section>
  )
}

const StationerySection = () => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/flare/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  return (
    <section className={styles.section + ' ' + styles.stationerySection}>

      <br /><br /><br />
       
      <Img
        className={styles.illustration}
        fluid={findImage(images, 'flare-stationery')}
        alt="Flare Stationery"
      />

      <WorkTitleBlock {...frontmatter} />
    </section>
  )
}

export default FlareLogo
